import axios from 'axios';

import { toast } from 'react-toastify';
import {
  profilegetAPI,
  profilepostAPI,
} from '../../api/profileApi';

import {
  GET_PROFILE_INFO,
  GET_PROFILE_INFO_ERROR,
} from './profile.types';

toast.configure();

export const postProfileInfo = (first_name, last_name, bio, gender, occupation, website) => (dispatch, getState) => {
  console.log(gender);
  if (gender === 'Other') {
    gender = 'O';
  } else if (gender === 'Male') {
    gender = 'M';
  } else if (gender === 'Female') {
    gender = 'F';
  } else {
    gender = '';
  }
  console.log(gender);
  const profileData = {
    first_name,
    last_name,
    bio,
    gender,
    occupation,
    website,

  };
  const { token } = getState().auth;
  // headers

  axios({
    url: profilepostAPI,
    method: 'POST',
    data: profileData,
    headers: {

      Authorization: `Token ${token}`,
    },
  })
    .then((res) => {
      localStorage.setItem('profileId', res.data.data.id);
      if (res.data.status === 404) {
        dispatch({
          type: GET_PROFILE_INFO_ERROR,
          payload: res.data,
        });
        toast.error('Please fill valid details', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        dispatch({
          type: GET_PROFILE_INFO,
          payload: res.data,
        });
        toast.success('Success', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch((err) => {
      console.log(err.response);
    });
};

export const getProfileInfo = () => (dispatch, getState) => {
  // Get token from state
  const { token } = getState().auth;
  // headers
  const config = {
    headers: {
      'content-type': 'application/json',
    },
  };
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  axios
    .get(profilegetAPI, config)
    .then((res) => {
      console.log(res);
      localStorage.setItem('profileId', res.data.data.id);
      if (res.data.status === 404) {
        dispatch({
          type: GET_PROFILE_INFO_ERROR,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_PROFILE_INFO,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.log(err.response);
    });
};
