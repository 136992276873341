import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import $ from 'jquery/dist/jquery.min'
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.js';
import 'popper.js/dist/popper.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Home from './pages/Home/Home';
import Aboutus from './pages/AboutUs/Aboutus';
import Contact from './pages/Contact/Contact';
import Blog from './pages/Blog/Blog';
import BlogTemplate from './pages/Blog/BlogTemplate';
import Mission from './pages/Mission/Mission';
import Categorydetail from './pages/Categorydetail/Categorydetail';
import Courses from './pages/Courses/Courses';
import Loginpage from './pages/Loginpage/Loginpage';
import Registerpage from './pages/Registerpage/Registerpage';
import Coursedetail from './pages/Coursedetail/Coursedetail';
// import Preloader from './components/Preloader/Preloader';
import Profilepage from './pages/Profilepage/Profilepage';
import Profilesettingspage from './pages/Profilepage/Profilesettingspage';
import Profilepasswordpage from './pages/Profilepage/Profilepasswordpage';
import PrivateRoute from './pages/PrivateRoute/PrivateRoute';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
// import { UserContext } from './Context/UserContext';
// import Loader from './components/Loader/Loader'
// import { reducer , initialState } from './Context/authReducer'

// import { store } from './Redux/store';

function App(props) {
  return (
    <div className="App">
      <div className="container">
        {props.isEmailConfirmed ? null
          : props.isAuthenticated ? (
            <div className="alert alert-danger alert-dismissible fade show text-center" role="alert" style={{ marginTop: '80px' }}>
              We have sent you a mail please verify by clicking link in it!
            </div>
          )
            : null}

      </div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/aboutus" component={Aboutus} />
          <Route exact path="/contactus" component={Contact} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/mission" component={Mission} />
          <Route exact path="/category/:id" render={(props) => <Categorydetail {...props} />} />
          <Route exact path="/:id/detail" component={Courses} />
          <Route exact path="/login" component={Loginpage} />
          <Route exact path="/register" component={Registerpage} />

          <PrivateRoute exact path="/myprofile/profile-settings" component={Profilesettingspage} />
          <PrivateRoute exact path="/myprofile/password-settings" component={Profilepasswordpage} />
          <PrivateRoute exact path="/myprofile/:user" component={Profilepage} />

          <Route exact path="/course/:title" render={(props) => <Coursedetail {...props} />} />
          <Route exact path="/blog/:ctg/:title/:pk/" render={(props) => <BlogTemplate {...props} />} />
          <Route exact path="/confirm-mail/:userid" component={ConfirmEmail} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

App.propTypes = {
  isEmailConfirmed: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isEmailConfirmed: state.auth.isEmailConfirmed,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
