import React, { Component } from "react";
import "./Preloader.styles.scss";
// import $ from "jquery";
class Preloader extends Component {
  // componentDidMount() {
  //   setTimeout(function () {
  //     $("#hellopreloader").fadeOut(800);
  //   }, 600);
  // }
  render() {
    return (
      <div id='hellopreloader'>
        <div class="loader"></div>
      </div>
    );
  }
}

export default Preloader;
