import React from 'react'
import './Profile.scss'
import { NavLink } from "react-router-dom";
export default function Fixedsettingbar() {
	return (
		<>
			<div className="sidebar-wrapper">
		      <div className="ps-block-sidebar">
		        <NavLink to= {`/myprofile/@${localStorage.getItem('username')}`} style={{ textDecoration: "none" }} >
		          <div className="ps-block-title">Profile</div>
		        </NavLink>
		        <NavLink to='/myprofile/profile-settings' style={{ textDecoration: "none" }} >
		          <div className="ps-block-title">Profile Settings</div>
		        </NavLink>
		       	<NavLink to='/myprofile/password-settings' style={{ textDecoration: "none" }}>
		          <div className="ps-block-title">Password Settings</div>
		        </NavLink>
		      </div>
		    </div>
		</>
	)
}