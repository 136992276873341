import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import NavBar from '../../components/NavBar/NavBar'
import Footer from '../../components/Footer/Footer'
import Cards from '../../components/cards/Cards'
import Subjects from '../../components/Subjects/Subjects'
import TextImage from '../../components/TextImage/TextImage'
import Category from '../../components/Category/Category'
// import Searchbar from '../../components/SearchBar/Searchbar'
import Teachers from '../../components/Teachers/Teachers'
import './Home.scss'
import { Button } from 'react-bootstrap'
import lab from './lab.png'

import Jumbo from '../../components/Jumbo/Jumbo'
export class Home extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid ">
                    <NavBar />
                    <Jumbo />
                </div>

                <div class="container justify-content-center text-center" style={{marginTop: "100px"}}>
                   
                    <span style={{color: "#fe4a55"}}>Education for everyone</span>
                    <h2 style={{fontWeight: "900"}}>Affordable Online Courses and Learning Opportunities​</h2>
                    <p style={{color: "#606060"}}>Finding your own space and utilize better learning options can result in faster than the traditional ways. Enjoy the beauty of eLearning!</p>
                    
                </div>

                <div className='container' style={{marginTop: "100px"}}>
                    <Category />
                </div>
               
                <div className="container" style={{marginTop: "100px"}}>
                    <Teachers />
                </div>

                <div class="container-fluid mb-3" style={{marginTop: "100px"}}>
                   <div className="row" style={{backgroundColor: "#FEF8EF"}}> 
                        <div className="col-lg-6 col-md-6 col-sm-12 p-5 ">
                            <p style={{color: "#fe4a55"}}>Distant Learning</p>
                            <h2 style={{fontWeight: "900"}}>Feel Like You Are Attending Your Classes Physically!</h2 >
                            <p style={{color: "#606060"}}>
                                Our programs can bring you a super exciting experience of learning through online! 
                                You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. 
                                Our flexible learning initiatives will help you to learn better and quicker than the traditional ways of learning skills.
                            </p>
                            <Link className='nav-link pl-4' to='/register'><Button variant="dark" style={{backgroundColor: "#fe4a55", borderColor: "#fe4a55"}}>
                            View Courses </Button></Link>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <img src={lab} className="imgtopic updown" alt=""/>
                        </div>
                    </div>  
                </div>

               
                

                <div className="container section2 " style={{marginTop: "100px"}} >
                    <div style={{color: "#fe4a55"}} className='text-center '>Learn At Your Own Pace</div>
                    <h1 className="heading2">
                    Our Popular Courses
                    </h1>
                    <p style={{color: "#606060"}} className='text-center'>
                    Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!
                    </p>
                    
                    <br />
                    
                    <Cards />
                    <p style={{color: "#606060"}} className='text-center'>
                     Enjoy the top notch learning methods and achieve next level skills! 
                     <br />You are the creator of your own career & we will guide you through that. <Link to='/register' style={{color: "#fe4a55"}}>Register Free Now!</Link>
                    </p>
                   
                </div>


                <div className="container " style={{marginTop: "100px"}}>
                    <h1 className="heading2">
                        Explore Our Top Subjects
                    </h1><br />
                    
                    <Subjects />
                                    
                                    
                </div>
                
               
                <div class="container-fluid" style={{marginTop: "100px"}}>
                    <TextImage />  
                </div>

                <div className="container-fluid p-0">
                    <Footer />
                    
                </div>
                
                
            </div>
        )
    }
}

export default Home
