import React ,{ Component }from 'react'
import './Contactform.scss'
import axios from "axios"; 
// import { Link } from 'react-router-dom'
// import Alert from '../Alerts/Alert'
import {Button, Form, Col, Spinner } from 'react-bootstrap';

import { contactAPI } from '../../api/contactApi'
import { toast } from "react-toastify";
toast.configure();
class ContactForm extends Component {
    state = {
      first_name: '',
      last_name: '', 
      email: '', 
      text: '',
      loading: false,
    };
    
    handleSubmit = event => {
      event.preventDefault();
      this.setState({ loading: true});

      axios.post(contactAPI , { 
          first_name: this.state.first_name,
          last_name: this.state.last_name, 
          email: this.state.email, 
          text: this.state.text, 
        })
        .then(res=>{
          console.log(res);
          
          if (res.data.status===201){
            this.setState({ loading: false});
            toast.success(res.data.msg, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("form").reset();
          }
          else if (res.data.status===400){
            this.setState({ loading: false});
            toast.error(res.data.msg, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(error => {
          this.setState({ loading: false});
          toast.error("The server is not excepting any request at this moment!! Try again later", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

        });
       
    }
  handleChange = event =>{
      this.setState({ [event.target.name]: event.target.value,         
      });
    }
  render() {
      
      return (
        
        <div class="container-fluid contactpage ">
        <div className="row ">
            <div className="col-sm-3 col-md-6 col-lg-6 col-12">
              <div class="contact-info">
                  <span class="sub-title">Contact Details</span>
                  <h2>Get in Touch</h2>
                  
                  <ul>
                    <li>
                      <div class="icon">
                       <i class="fa fa-map-marker" aria-hidden="true"></i>

                      </div>
                      <h3>Our Address</h3>
                      <p>Ranikhet. India</p>                                    
                    </li>
                    <li>
                      <div class="icon">
                       <i class="fa fa-envelope" aria-hidden="true"></i>
                      </div>
                      <h3>E-mail</h3>
                        <p>Mail: <a href="mailto:prashantpandey94551@gmail.com">prashantpandey94551@gmail.com</a></p>
                                                         
                    </li>
                    <li>
                      <div class="icon">
                        <i class="fa fa-phone" aria-hidden="true"></i>

                      </div>
                      <h3>Contact</h3>
                      <p>Mobile: <a href="tel:+91-8057152295">+91-8057152295</a></p>
                      
                    </li>
                        
                  </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-12 contact-info contactus">
                <h2 className='text-center p-3 '>Ready to Get Started?</h2>
                <Form 
                      name="contact_form" 
                      className='' 
                      onSubmit = {  this.handleSubmit }
                      id='form'
                    >
                      <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>First Name *</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="first_name" 
                            placeholder="First Name" 
                            onChange= {this.handleChange} 
                            required
                          />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="last_name" 
                            placeholder="Last Name" 
                            onChange= {this.handleChange} 
                             
                          />
                        </Form.Group>
                      </Form.Row>

                     
                      <Form.Group controlId="formGridAddress2">
                        <Form.Label>Email *</Form.Label>
                        <Form.Control 
                          type="email" 
                          name="email" 
                          placeholder="E-mail" 
                          onChange= {this.handleChange} 
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Message *</Form.Label>
                        <Form.Control 
                          as="textarea"  
                          rows="4" 
                          name="text" 
                          placeholder="Write Your Message..." 
                          onChange= {this.handleChange} 
                          required
                          />
                      </Form.Group>

                      <Button type='submit' variant='dark'>
                            {this.state.loading ? 
                              <>
                              <span>Loading... </span>
                              <Spinner animation="border" size="sm" />
                              
                              </>
                     :
                    "Submit" 
                  }
                     
                          </Button>

                      
                    </Form>
                
            </div>
        </div>
        
    </div>
      );
    }
  }
  export default ContactForm;

