 import React from 'react'
 import Register from '../../components/Register/Register'
 import Navbar  from '../../components/NavBar/NavBar'
 import Footer from '../../components/Footer/Footer'
 export default function Registerpage() {
     return (
         <div>
             <Navbar />
             <Register />
             <Footer />
         </div>
     )
 }
 