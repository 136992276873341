import axios from 'axios';
import { toast } from 'react-toastify';
import { loginAPI, registerAPI } from '../../api/authApi';
import {
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  NON_FIELD_ERRORS,
  EMAIL_CONFIRMED,
  REMOVE_ERROR,
} from './auth.types';

toast.configure();
// Login User
export const loading = () => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING,
  });
};

export const login = (username, password) => (dispatch) => {
  // headers
  const config = {
    headers: {
      'content-type': 'application/json',
    },
  };
  // Body
  const body = JSON.stringify({
    username,
    password,
  });

  axios
    .post(loginAPI, body, config)
    .then((response) => {
      if (response.data.status === 400) {
        dispatch({
          type: NON_FIELD_ERRORS,
          payload: response.data,
        });
        toast.error(response.data.msg, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.data.status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        dispatch({
          type: NON_FIELD_ERRORS,
        });
        toast.error('Something went wrong!!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: NON_FIELD_ERRORS,
      });
      toast.error('Something went wrong!!', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

// LOGOUT
export const logout = () => (dispatch, getState) => {
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const emailConfirm = () => (dispatch, getState) => {
  dispatch({
    type: EMAIL_CONFIRMED,
  });
};

//  Register User
export const register = (username, email, password, first_name, last_name) => (
  dispatch,
) => {
  // headers
  const config = {
    headers: {
      'content-type': 'application/json',
    },
  };
  // Body
  const body = JSON.stringify({
    username,
    email,
    password,
    first_name,
    last_name,
  });

  axios
    .post(registerAPI, body, config)
    .then((response) => {
      dispatch({
        type: USER_LOADING,
      });
      if (response.data.status === 400) {
        dispatch({
          type: NON_FIELD_ERRORS,
          payload: response.data,
        });
        toast.error(response.data.msg, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.data.status === 201) {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        dispatch({
          type: NON_FIELD_ERRORS,
        });
        toast.error('Something went wrong!!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: NON_FIELD_ERRORS,
      });
      toast.error('Something went Wrong!!', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
};

export const removeError = () => (dispatch, getState) => {
  dispatch({
    type: REMOVE_ERROR,
  });
};
