import React, { Component } from 'react'
import NavBar from '../../components/NavBar/NavBar'
import Footer from '../../components/Footer/Footer'
import LinkTitle from '../../components/LinkTitle/LinkTitle'
// import Subjects from '../../components/Subjects/Subjects'
// import Cards from '../../components/cards/Cards'
import './Aboutus.scss'
export class Aboutus extends Component {
    render() {
        return (
            <>
            <NavBar />
            <LinkTitle data="About Us" linkto='aboutus'/>
            <div className="container-fluid p-0 aboutus text-center">
                <div className="container p-5 ">
                    <div className="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="our-story-title" style={{paddingTop:'30px'}}>
                                <h3>Our Story</h3>
                                <h6>"We are changing the way people think about the future."</h6>
                            </div>
                            <hr style={{width: "30%" }}/>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <div class="our-story-content" style={{color:'#606060'}}>
                                <p> Recreately.com has been launched in November 2020, as an online learning forum. Our company has been formed to create value & serve the people in an unconventional manner. The company has a firm conviction in building a community of progressive, visionary & zealous individuals. On the other hand, the organization is based on the core fundamentals of authenticity, innovation & optimism.</p>    
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="container p-5 ">
                    <div className="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="our-story-title bold" style={{paddingTop:'30px'}}>
                                <h3>Recreately.com’s Approach</h3>
                                <hr style={{width: "30%" }} />
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <div class="our-story-content" style={{color:'#606060'}}>
                                <p>We at Recreately.com have a specific purpose of providing assistance to the youth by helping them build their 
                                    careers as well as shape their personalities. We also cater to children, working professionals and elderly ones by 
                                    facilitating them to restart their passion in music, language, sports etc. Our sole objective is to revolutionize the 
                                    future possibilities by assisiting the candidates in unleashing their hidden potentials. We aim to aid the youth by 
                                    becoming a sturdy backbone in their journey by helping them achieve their dreams. Here at Recreately, we give wings 
                                    to the dreams while believing in ‘Sky is the limit’ formula. We also aim to instill and enhance the qualities like 
                                    leadership, sportsmanship, innovation and curiosity by providing a range of interesting courses in all the categories. 
                                    Here at recreately.com, we create the desired future.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        )
    }
}

export default Aboutus
