import React from 'react'
import topic1 from '../../assets/images/topic1.png'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
export default function TextImage() {
    return (
        <div className="row py-5" style={{backgroundColor: "#fff"}}> 
            <div className="col-lg-6 col-md-6 col-sm-12 p-5">
                <p style={{color: "#fe4a55"}}>Get Instant Access to the Free</p>
                <h2 style={{fontWeight: "900"}}>Self Development Course</h2 >
                <p style={{color: "#606060"}}>Self Development Course can assist you in bringing the significant changes in personal understanding and reshaping the confidence to achieve the best from your career! We trust that learning should be enjoyable, and only that can make substantial changes to someone!</p>
                <Link className='nav-link pl-4' to='/register'><Button variant="dark" style={{backgroundColor: "#fe4a55", borderColor: "#fe4a55"}}>
                Start For Free </Button></Link>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <img src={topic1} className="imgtopic" alt=""/>
            </div>
        </div>  
    )
}
