import React, { Component } from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom'
export class Footer extends Component {
    render() {
        return (
          <footer className="footer container-fluid">
              <div className="container p-0">
                  <div className="row justify-content-center p-4">
                    <div className="col-sm-12 col-md-3 col-lg-3 p-2 text-center justify-content-center">
                     <div><h1>Recreatly</h1></div>
                      <p>We are changing the way people think about the future.</p>
                      <a href="https://prashantpandey.ml/"><i className="fa fa-twitter"></i></a>
                      <a href="https://prashantpandey.ml/"><i className="fa fa-linkedin"></i></a>
                      <a href="https://prashantpandey.ml/"><i className="fa fa-github"></i></a>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">     
                       <div className="dropdown-header">Company</div>
                       <Link className="dropdown-item" to='/aboutus'>About</Link>
                       <Link className="dropdown-item" to='/contactus'>Contact Us</Link>
                       <Link className="dropdown-item" to="/">Websites</Link>
                    </div>
                  <div className="col-sm-12 col-md-3 col-lg-3">
                   <div className="dropdown-header">Professional Services</div>
                   <Link className="dropdown-item" to="/">Project rescue</Link>
                   <Link className="dropdown-item" to="/">Source code recovery</Link>
                   <Link className="dropdown-item" to="/">Application </Link>
                   <div>
                  </div>
                  </div>

                  <div className="col-sm-12 col-md-3 col-lg-3">
                   <div className="dropdown-header">Fixed Price Services</div>

                       <Link className="dropdown-item" to="/">Add cookie consent</Link>
                       <Link className="dropdown-item" to="/">Add captcha</Link>
                       <Link className="dropdown-item" to="/">Add core data</Link>
                       <Link className="dropdown-item" to="/">Custom error pages</Link>
                       <Link className="dropdown-item" to="/">Contact form creation</Link>
                       <Link className="dropdown-item" to="/">Automated backups</Link>
                       <Link className="dropdown-item" to="/">Image to HTML</Link>
                   </div>
                  </div>
                  <div className="dropdown-divider"></div>
                  <div className="row align-items-center justify-content-center p-1">
                    <h6>&copy;Copyright ©2020 All rights reserved</h6>
                  </div>
              </div>  
          </footer>
        )
    }
}

export default Footer
