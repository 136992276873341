import React from 'react'
import Navbar from '../../components/NavBar/NavBar'
import LinkTitle from '../../components/LinkTitle/LinkTitle'
import Footer from '../../components/Footer/Footer'
import Profile from '../../components/Profile/Profile'
export default function Profilepage() {
	return (
		<div >
			<Navbar />
            <LinkTitle data='Profile' linkto='profile'/> 
            <Profile />
            <Footer />
		</div>
	)
}