import React, { useEffect} from 'react'
import { Link } from "react-router-dom";
import './Profile.scss';
import {Button, Col,Row } from 'react-bootstrap';
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { store } from "../../Redux/store";
import Fixedsettingbar from './Fixedsettingbar'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getProfileInfo } from "../../Redux/profileinfo/profile.actions";
import { faSuitcase, faSmileBeam, faGlobe, faEnvelope } from '@fortawesome/free-solid-svg-icons'
function Profile(props) {
  useEffect((props) => {
    setTimeout(() => {
        props.getProfileInfo();
      }, 2000);
  }, [])


  const {
      first_name,
      last_name,
      bio,
      email,
      gender,
      occupation,
      website,
    } = props.details;
	return (
		<div className='container-fluid '>
			<div className='row'>
				<div className="col-sm-12 col-md-3 col-lg-3">
					<Fixedsettingbar />
				</div>

				<div className="col-sm-12 col-md-9 col-lg-9">
					<div className='profilePageBody'>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              
              <Col xl={12} lg={12} xs={12}>
               
                  <div className='profile-pageCard'>
                    <div>
                      <h3>My Profile</h3>
                      <hr />
                      
                      
                      <div className='profile-username'>
                        {props.username && <h5>@{props.username}</h5>}
                      </div>
                      <h4 className='profile-name'>
                        {first_name} {last_name}
                      </h4>
                      
                      {bio && <div className='profile-bio mt-4'>{bio}</div>}
                      <div className='text-center'>
                        <Link to='/myprofile/profile-settings'>
                          <Button size='sm' variant='dark'>
                            Edit Profile
                          </Button>
                        </Link>
                      </div>

                      <hr className='mb-4'></hr>

                      <div className='detailsWrapper'>
                        <h5 className='profile-option'>Contact</h5>

                        {email && (
                          <h6 className='profile-option'>
                            <FontAwesomeIcon
                              className='mr-2 mt-1'
                              icon={faEnvelope}
                            />{" "}
                            {email}
                          </h6>
                        )}

                        

                        <hr className='mb-4'></hr>

                        <h5 className='profile-option'>Occupation</h5>
                        {occupation && (
                          <h6 className='profile-option'>
                            <FontAwesomeIcon className='mr-2' icon={faSuitcase} />{" "}
                            {occupation}
                          </h6>
                        )}

                        <hr className='mb-4'></hr>
                        <h5 className='profile-option'>Personal Details</h5>

                        {gender && (
                          <h6 className='profile-option mr-2'>
                            <FontAwesomeIcon className='mr-2' icon={faSmileBeam} />{" "}
                            {gender === "O"
                              ? "Other"
                              : gender === "M"
                              ? "Male"
                              : gender === "F"
                              ?"Female": ''

                            }
                          </h6>
                        )}

                        

                        {website && (
                          <h6 className='profile-option'>
                            <FontAwesomeIcon className='mr-2' icon={faGlobe} />
                            {website}
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
               
              </Col>
              
            </Row>
            
          </div>
				</div>
			</div>
		</div>
	)
}

Profile.propTypes = {
    details: PropTypes.object,
    username: PropTypes.string,
};
const mapStateToProps = (state) => ({
  details: state.profile.details,
  username: state.auth.user
});

export default connect(mapStateToProps, { getProfileInfo })(Profile);
