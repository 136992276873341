import {
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  NON_FIELD_ERRORS,
  EMAIL_CONFIRMED,
  REMOVE_ERROR,
} from './auth.types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  error: null,
  user_id: null,
  email: null,
  isEmailConfirmed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOGIN_SUCCESS:

      localStorage.setItem('token', action.payload.data.token);
      localStorage.setItem('user_id', action.payload.data.user_id);
      localStorage.setItem('email', action.payload.data.email);
      localStorage.setItem('first_name', action.payload.data.first_name);

      return {
        ...state,
        token: action.payload.data.token,
        user: action.payload.data.username,
        user_id: action.payload.data.user_id,
        email: action.payload.data.email,
        isAuthenticated: true,
        isLoading: false,
        error: action.payload.msg,
      };
    case REGISTER_SUCCESS:

      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('email', action.payload.data.email);
      localStorage.setItem('first_name', action.payload.data.first_name);
      localStorage.setItem('user_id', action.payload.data.user_id);
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: true,
        isLoading: false,
        email: action.payload.data.email,
        user: action.payload.data.username,
        user_id: action.payload.data.user_id,
        error: action.payload.msg,
      };
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
    case REMOVE_ERROR:
    case REGISTER_FAILED:

      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('first_name');
      localStorage.removeItem('user_id');
      window.location.reload();
      return {
        ...state,
        token: null,
        isAuthenticated: null,
        isLoading: false,
        user: null,
        email: null,
        error: null,
        user_id: null,
        isEmailConfirmed: false,
      };

    case EMAIL_CONFIRMED:
      return {
        ...state,
        isEmailConfirmed: true,
      };
    case NON_FIELD_ERRORS:
      return {
        ...state,
        token: null,
        email: null,
        isAuthenticated: null,
        isLoading: false,
        user: null,
        error: '',
        user_id: null,
        isEmailConfirmed: false,
      };

    default:
      return state;
  }
}
