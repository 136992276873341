import React from 'react';
import { Row, Col }from 'react-bootstrap'
import face from '../../assets/images/faceshield.png'
import './Jumbo.scss'
import Searchbar from '../SearchBar/Searchbar'

const Jumbo = () => {
   return (
    <Row className="section1 ">
      <Col xs={12} md={12} lg={6}>
         <div className="main-banner-content mx-auto text-center">
           <h1 className="heading">The World’s Leading Distance Learning Provider</h1>
            <p className="para1">Flexible easy to access learning opportunities can bring a significant change in how individuals prefer to learn! The Recreatly can offer you to enjoy the beauty of eLearning!</p>
         </div>
      </Col>
      <Col xs={12} md={12} lg={6}>
           <img src={face} alt="faceshield" className='imageside' />
      </Col>
      <Col xs={12} md={12} lg={3}></Col>
      <Col xs={12} md={12} lg={6}>
        <div className='justify-content-center'>
        <Searchbar  />
      </div>
      </Col>
    </Row>
   );
}
export default Jumbo;

