import React, {useState} from 'react'
import './Profile.scss'
import {Redirect} from 'react-router-dom'
import {Button,Spinner, Form, Col,Row } from 'react-bootstrap';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Fixedsettingbar from './Fixedsettingbar'
import { postProfileInfo } from "../../Redux/profileinfo/profile.actions";

function Profilesettings(props) {
	const initialState = {
      first_name: props.details.first_name,
      last_name: props.details.last_name,
      bio: props.details.bio, 
      gender:
               props.details.gender === "O"
                 ? "Other"
                 : props.details.gender === "M"
                 ? "Male"
                 : props.details.gender === "F"
                 ? "Female"
                 : "Select",
      occupation: props.details.occupation,
      website: props.details.website,
      redirect: false,
      loading: false,
      email: localStorage.getItem("email"),

     };
    
     const [data, setData] = useState(initialState);

     
    
     const handleInputChange = event => {
      setData({
        ...data,
        [event.target.name]: event.target.value
      });
    };

    const onSumbmitHandler = event => {
    	event.preventDefault();
    	setData({
	    	...data, loading: true
	    })
	    setTimeout(() => {
	      props.postProfileInfo(data.first_name, data.last_name,data.bio,data.gender, data.occupation,data.website);
	      setData({
	    	...data, redirect: true
	      })
	    setData({
	    	...data, loading: false
	    })
	    }
	    , 2000);
	    

    }
if (data.redirect){
	return <Redirect to={`/myprofile/${localStorage.getItem('username')}`} />
}
	
	return (
		<div className='container-fluid '>
			<div className='row'>
				<div className="col-sm-12 col-md-3 col-lg-3">
					<Fixedsettingbar />
				</div>

				<div className="col-sm-12 col-md-9 col-lg-9">
					<div className='profilePageBody' id='profilePageBody'>
						<Row style={{ marginLeft: 0, marginRight: 0 }}>
				          <Col xl={12} lg={12} md={12} sm={12}>
				            <div className='profile-pageCard'>
				              <div>
				                <h3>Profile Settings</h3>
				                <hr />
				                <Form
				                  className='profile-formAdjust'
				                  onSubmit={onSumbmitHandler}
				                >
				                  <h5 className='profile-username'>@{props.username}</h5>

				                  <Form.Row>
				                    <Col xl={6} lg={6} md={6} sm={12}>
				                      <Form.Group>
				                        <Form.Label>First Name*</Form.Label>
				                        <Form.Control
				                          type='text'
				                          placeholder='Your first name'
				                          name='first_name'
				                          value={data.first_name}
				                          onChange={handleInputChange}
				                          required
				                        />
				                      </Form.Group>
				                    </Col>
				                    <Col xl={6} lg={6} md={6} sm={12}>
				                      <Form.Group>
				                        <Form.Label>Last Name*</Form.Label>
				                        <Form.Control
				                          type='text'
				                          placeholder='Your last name'
				                          name='last_name'
				                          value={data.last_name}
				                          onChange={handleInputChange}
				                          required
				                        />
				                      </Form.Group>
				                    </Col>
				                  </Form.Row>
				                  <Form.Group controlId='bio'>
				                    <Form.Label>Bio*</Form.Label>
				                    <Form.Control
				                      as='textarea'
				                      rows='3'
				                      placeholder='Your profile bio'
				                      name='bio'
				                      value={data.bio}
				                      onChange={handleInputChange}
				                      
				                    />
				                  </Form.Group>
				                  <Form.Row>
				                    <Col xl={12} lg={12} md={12} sm={12}>
				                      <Form.Group controlId='email'>
				                        <Form.Label>Email*</Form.Label>
				                        <Form.Control
				                          type='email'
				                          style={{cursor: "not-allowed"}}
				                          value={data.email}
				                          
				                          readOnly
				                          
				                        />
				                      </Form.Group>
				                    </Col>
				                  </Form.Row>
				                  <Form.Row>
				                    <Col xl={6} lg={6} md={6} sm={12}>
				                      <Form.Group controlId='gender'>
				                        <Form.Label>Gender*</Form.Label>
				                        <Form.Control
				                          as='select'
				                          name='gender'
				                          value={data.gender}
				                          onChange={handleInputChange}
				                          
				                        >
				                          <option>Select</option>
				                          <option>Female</option>
				                          <option>Male</option>
				                          <option>Other</option>
				                        </Form.Control>
				                        
				                      </Form.Group>
				                    </Col>
				                    <Col xl={6} lg={6} md={6} sm={12}>
				                      <Form.Group controlId='occupation'>
				                        <Form.Label>Occupation*</Form.Label>
				                        <Form.Control
				                          type='text'
				                          placeholder='Enter your Occupation'
				                          name='occupation'
				                          value={data.occupation}
				                          onChange={handleInputChange}
				                          
				                        />
				                      </Form.Group>
				                    </Col>
				                  </Form.Row>
				                  <Form.Group controlId='blogUrl'>
				                    <Form.Label>Website / Blog URL</Form.Label>
				                    <Form.Control
				                      type='text'
				                      placeholder='Your website / blog URL'
				                      name='website'
				                      value={data.website}
				                      onChange={handleInputChange}
				                    />
				                  </Form.Group>
				                  <Button type='submit' variant='dark'>
				                    {data.loading ? 
				                    	<>
				                    	<span>Loading... </span>
				                    	<Spinner animation="border" size="sm" />
				                    	
				                    	</>
										 :
										"Save Changes" 
									}
				                  </Button>
				                </Form>
				              </div>
				            </div>
				          </Col>
				        </Row>
					</div>
				</div>
			</div>
		</div>
	)
}
Profilesettings.propTypes = {
    details: PropTypes.object,
    username: PropTypes.string,
};
const mapStateToProps = (state) => ({
  details: state.profile.details,
  username: state.auth.user,
});

export default connect(mapStateToProps, { postProfileInfo })(Profilesettings);
