import {
  GET_PROFILE_INFO,
  GET_PROFILE_INFO_ERROR,
} from './profile.types';

const initialState = {
  details: {
    first_name: '',
    last_name: '',
    bio: '',
    email: '',
    gender: '',
    occupation: '',
    website: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE_INFO:
      return {
        ...state,
        details: {
          first_name: action.payload.data.first_name,
          last_name: action.payload.data.last_name,
          bio: action.payload.data.bio,
          email: action.payload.data.email,
          gender: action.payload.data.gender,
          occupation: action.payload.data.occupation,
          website: action.payload.data.website,
        },
      };
    case GET_PROFILE_INFO_ERROR:
      return {
        ...state,
        error: action.payload.data,
      };
    default:
      return state;
  }
}
