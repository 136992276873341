import React, { useState, useEffect } from 'react';
import {  Link } from "react-router-dom";
import { confirmEmailAPI } from "../../api/profileApi";
import axios from "axios";
import { toast } from "react-toastify";
import "./ConfirmEmail.scss";
import { Alert, AlertTitle } from "@material-ui/lab";
// import { Button } from 'react-bootstrap';
import { store } from "../../Redux/store";
import PropTypes from "prop-types";
import { emailConfirm } from "../../Redux/auth/auth.actions";
import { connect } from "react-redux";
toast.configure();

function ConfirmEmail(props) {
  const userid = props.match.params.userid.slice(60);
  const localid = localStorage.getItem('user_id')
  const token = localStorage.getItem('token')
  // console.log(userid, localid)
  const initialState = {
    res: '',
    error: ''
  }
  const [state, setState] = useState(initialState)
  
  useEffect(() => {
    if (userid === localid) {
      axios({
        url: `${confirmEmailAPI}${userid}/`,
        method: "GET",
        headers: {
            "Authorization": `Token ${token}`,
          },
      })
        .then((res) => {
          setState({ res: res.data });
          toast.success("Email confirmed!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          store.dispatch(emailConfirm());
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setState({ error: err.response.data.Abort });
          }
        });
    }
  }, [localid, userid, token])
  return (
    <div>
        {state.res.status === 200 ? (
          <div className='email-message container'>
            <Alert severity='success' style={{ fontSize: "25px" }}>
              <AlertTitle style={{ fontSize: "25px" }}>Verified</AlertTitle>
            </Alert>
            <div className='response'>
              {state.res.data} —{" "}
              <Link to='/'>
                <strong style={{ color: "#212529" }}>Go to Home Page!</strong>
              </Link>
            </div>
          </div>
        ) : (
          <div className='email-message container'>
            <Alert severity='error' style={{ fontSize: "25px" }}>
              <AlertTitle style={{ fontSize: "25px" }}>Error</AlertTitle>
            </Alert>
            <div className='response'>
              OOPS!! Something Went Wrong.. -
              <Link to='/login'>
                <strong style={{ color: "#212529" }}>Try to Login again!</strong>
              </Link>
              
            </div>
          </div>
        )}
      </div>
  )
}

ConfirmEmail.propTypes = {
    isAuthenticated: PropTypes.bool,
     emailConfirm: PropTypes.func.isRequired,
  };

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {emailConfirm})(ConfirmEmail);
