import React from 'react';
import './NavBar.scss'
import {Navbar, Nav, NavDropdown, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { store } from "../../Redux/store";
import { logout } from "../../Redux/auth/auth.actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
export  function Loggedout() {
    
    return (
        <Nav>
           
            <Link className='nav-link pl-4' to='/register'><Button variant="dark">Register</Button></Link>
            <Link className='nav-link pl-4' to='/login'><Button variant="dark">Login </Button></Link>
                    
        </Nav>
    )
}

export  function Loggedin(props) {
    return (
        <>
        <Nav className='mr-3'>

            <Link className='nav-link pl-4' to='/login'>My Courses</Link> 

            <NavDropdown title={`Hey ! ${localStorage.getItem('first_name')}`} id="collasible-nav-dropdown" className='dropbg pl-4'>
                <Link className="dropdown-item" to={`/myprofile/@${localStorage.getItem('username')}`}>Your Profile</Link>
                <Link className="dropdown-item" to='/'>Manage Cart</Link> 
                <Link className="dropdown-item" to=''
                onClick={()=>{
                    setTimeout(() => {
                      store.dispatch(logout());
                    }, 1000);           
                    }}
                >Logout</Link>
               
            </NavDropdown>     
        </Nav>
        
        </>
    )
}




export  const NavBar = (props)=> {
        return (
            <>
            <div>
                <Navbar collapseOnSelect expand="lg" className="background" fixed="top">
                <Navbar.Brand><Link to='/'>Recreatly</Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto ml-auto">

                    <Link className='nav-link pl-4' to='/'>Home</Link>
                    <Link className='nav-link pl-4' to='/blog'>Blog</Link>
                    <Link className='nav-link pl-4' to='/aboutus'>About Us</Link>
                    
                    <li className="nav-item  dropdown  ">
                        <Link className="nav-link pl-4 dropdown-toggle" to="/" id="servicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Categories</Link>
                        <div className="dropdown-menu dropdown-menu-left " aria-labelledby="servicesDropdown">
                          
                         <div className="d-md-flex align-items-start justify-content-start">
                            <div>   
                               <div className="dropdown-header">Music</div>
                                 <Link className="dropdown-item" to="/">Guitar</Link>
                                 <Link className="dropdown-item" to="/">Tabla</Link>
                                 <Link className="dropdown-item" to="/">Keyboard</Link>
                                 <Link className="dropdown-item" to="/">Drum</Link>
                                 <Link className="dropdown-item" to="/">Santoor</Link>
                                 <Link className="dropdown-item" to="/">Harmonium</Link>
                                 <Link className="dropdown-item" to="/">Vocal</Link>

                               <div>
                                 <div className="dropdown-header">Dance</div>
                                 <Link className="dropdown-item" to="/">Western</Link>
                                 <Link className="dropdown-item" to="/"> Indian Classical</Link>
                                 <Link className="dropdown-item" to="/">Indian Movie Dance</Link>
                               </div>
                            </div>

                          <div>
                           <div className="dropdown-header">Language</div>
                           <Link className="dropdown-item" to="/">Spanish</Link>
                           <Link className="dropdown-item" to="/">Chinese</Link>
                           <Link className="dropdown-item" to="/">French</Link>
                           <Link className="dropdown-item" to="/">Telugu</Link>
                           <div>
                           <div className="dropdown-header">Drawing & Painting </div>
                           <Link className="dropdown-item" to="/">Sketching</Link>
                           <Link className="dropdown-item" to="/">Water Painting</Link>
                          </div>
                          </div>

                          <div>
                           <div className="dropdown-header">Sports</div>
                               <Link className="dropdown-item" to="/">Cricket</Link>
                               <Link className="dropdown-item" to="/">Chess</Link>
                               <Link className="dropdown-item" to="/">Badminton</Link>
                               <Link className="dropdown-item" to="/">Football</Link>
                               <Link className="dropdown-item" to="/">Basketball</Link>
                               <Link className="dropdown-item" to="/">Kabaddi</Link>
                               <Link className="dropdown-item" to="/">MMA</Link>
                           </div>
                         </div>
                        </div>
                    </li>
                    
                    <Link className='nav-link pl-4' to='/contactus'>Contact Us</Link>
                    <Link className='nav-link pl-4' to='/mission'>Our Mission</Link>
                    </Nav>
                    {props.isAuthenticated ? <Loggedin />: <Loggedout />}
                
                </Navbar.Collapse>
                </Navbar>
            </div>

            </>

        )
    
}
NavBar.propTypes = {
    logout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(NavBar);
