import React from 'react'
import {Button, Form } from 'react-bootstrap';
import { server } from '../../api/server'
export default function Categorytemplate(props) {
	const {
      title,
      price,
      about,
      teacher,
      categoryimage,
      urls,
    } = props;

	
	
    let teacherdetail=teacher.map((i, index)=>(
    	<div className='author_info'>
	        <div className='author_header'>
	            <div className='thumb'>
	                <img src={`${server}${i.teacherimage}`} className='author' alt="" />
	            </div>
	            <div className='name subject'>
	                <h6>{i.teachername}</h6>
	                <p className='text-center'>{i.workingas}</p>
	            </div>
	        </div>
	        <div className='subject text-center'>
	            <p>
	               {i.about}
	            </p>
	        </div>
	    </div>
    ) )

    let youtubeurls = urls.map((i,index)=>(
    	<>
    	<div className="col-md-12 author_info col-sm-12 col-lg-12 pl-4 embed-responsive embed-responsive-16by9">
            <iframe className='embed-responsive-item' title={i.yturl}
                width="560" 
                height="315" 
                src={i.yturl} 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
                
            </iframe>
        </div>
        </>
    	))


	return (
		<div className=''>
			<div className="container-fluid text categorypage ">
                <div className="container p-5 mt-4">
                    <div className="row p-5">
                        <div className="col-12 col-md-6 col-sm-12 col-lg-6 text-center">
                           <h3>
                               {title} 

                           </h3> 
                           <div className='prise'>
                                <span className="inactive">₹{price}.00</span>
                                <span className="active">₹{price}.00</span>
                            </div>
                           <h5>
                               
                           </h5>
                        </div>
                    </div> 
                </div>
            </div>

            <div className="container-fluid" style = {{backgroundColor: '#fff'}}>
                <div className="row p-5">
                    <div className="col-md-6 col-sm-12 col-lg-6 pl-4 subject" >
                        <h3>
                            About
                        </h3>
                        <p >
                            {about}
                        </p>
                      
                       <div className='row subject' >
                       	 <h3>Video Preview</h3>
                         {youtubeurls}
                       </div>
                    </div>
                    <div className="col-12 col-md-2 col-sm-12 col-lg-2"></div>
                    <div className="col-12 col-md-4 col-sm-12 col-lg-4" >
                        <img src={`${server}${categoryimage}`} alt="" className='courseimg rounded' />
                        {teacherdetail}
                        		
                       
                        <div className='author_info'>
                            <div>
                                <h6>
                                    Write your feedback
                                </h6>
                                <Form className='mt-2'>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows="6" />
                                  </Form.Group>
                                  <Button type='submit' variant='dark'>
                                        Save Changes
                                  </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>	
		</div>
	)
}
