import React, { useState, useEffect } from 'react'
import NavBar from '../../components/NavBar/NavBar'
import {Link} from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import { detailedblogpostAPI, yomaylikeAPI, postcommentAPI } from '../../api/blogpostApi'
import {Button, Form, Col, Spinner } from 'react-bootstrap';
import axios from "axios";
import { server } from '../../api/server'
import { toast } from "react-toastify";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";
toast.configure();
export default function BlogTemplate(props) {
	const id = props.match.params.pk
	const title1 = props.match.params.title
	const ctg = props.match.params.ctg
	const title= title1.replace(/-/g," ");
	const share = `${server}/blog/${ctg}/${title1}/${id}/`
	const initialState = {
		data: [],
		comment: [],
		id: '',
	}
	const initialState1 = {
		youmaylikedata: [],

	}
	const initialState2 = {
      commented_by: '',
      phone_number: '', 
      email: '', 
      content: '',
      loading: false,
    };
	const [state, setState] = useState(initialState)
	const [data, setData] = useState(initialState1)
	const [change, setChange] = useState(initialState2)
	useEffect(() => {
		axios
		.get(`${detailedblogpostAPI}${id}/`)
		.then((res)=>{
			setState({
	    	...state, data: res.data, comment: res.data.postcomment, id: res.data.id
	    	})
	    	
		})
		axios
		.get(`${yomaylikeAPI}${ctg}/`)
		.then((res)=>{
			
			setData({
	    	...data, youmaylikedata: res.data
	    	})
		})

	}, [id,change.loading, ctg])

	const handleChange = event =>{
      setChange({ ...change, [event.target.name]: event.target.value,         
      });
    }
    const handleSubmit = event => {
      event.preventDefault();
      setChange({...change, loading: true});

      axios.post(`${postcommentAPI}${state.id}/` , { 
          commented_by: change.commented_by,
          phone_number: change.phone_number, 
          email: change.email, 
          content: change.content, 
        })
        .then(res=>{
          
          if (res.data.status===201){
            setChange({loading: false});
            toast.success("Your comment is published!!!", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           document.getElementById("form").reset();
          }
          else if (res.data.status===400){
            setChange({...change, loading: false});
            toast.error(res.data.msg, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(error => {
          setChange({...change, loading: false});
          toast.error("The server is not excepting any request at this moment!! Try again later", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

        });
       
    }
	return (
		<>
			<NavBar />
			<div className='container linktitle text-center'>
				<h1 className='mt-5 linkhead'>{title}</h1>
				 By {state.data.posted_by} | Published {state.data.posted_on} 
				 <br />
				 <div className='justify-content-center mt-2'>
				 	<span className='px-1'>
					 <FacebookShareButton
			            url={share}
			            quote={title}
			            
			          >
			            <FacebookIcon size={25} round />
			          </FacebookShareButton>
			        </span>
			        <span className='px-1'>
			          <TwitterShareButton
			            url={share}
			            title={title}
			            
			          >
			            <TwitterIcon size={25} round />
			          </TwitterShareButton>
			        </span>
			        <span className='px-1'> 
			           <WhatsappShareButton
			            url={share}
			            title={title}
			            separator=":: "
			            
			          >
			            <WhatsappIcon size={25} round />
			          </WhatsappShareButton>
			        </span>
			        <span className='px-1'>
			           <LinkedinShareButton url={share} >
			            <LinkedinIcon size={25} round />
			          </LinkedinShareButton>
			        </span>
				 </div>
				 
			</div>
			<div className='container justify-content-center mb-2'>
				<div className='row' style={{backgroundColor: '#fff', borderRadius: "10px"}}>
					
					<div className='col-12 col-sm-12 col-md-8 col-lg-8 p-5' >
						<div dangerouslySetInnerHTML={{__html: `${state.data.content}`}} />
						<div className='justify-content-center mt-5'>
							<h4 className='ml-2'>Share</h4>
						 	<span className='px-2'>
							 <FacebookShareButton
					            url={share}
					            quote={title}
					            
					          >
					            <FacebookIcon size={25}  />
					          </FacebookShareButton>
					        </span>
					        <span className='px-2'>
					          <TwitterShareButton
					            url={share}
					            title={title}
					            
					          >
					            <TwitterIcon size={25}  />
					          </TwitterShareButton>
					        </span>
					        <span className='px-2'> 
					           <WhatsappShareButton
					            url={share}
					            title={title}
					            separator=":: "
					            
					          >
					            <WhatsappIcon size={25}  />
					          </WhatsappShareButton>
					        </span>
					        <span className='px-2'>
					           <LinkedinShareButton url={share} >
					            <LinkedinIcon size={25}  />
					          </LinkedinShareButton>
					        </span>
						</div>
						<div style={{marginTop: "100px"}}>
							<h3>Comments</h3>
							{state.comment.map((i)=>(
								<div className="container rounded m-3 p-3" style={{boxShadow: "0px 8px 16px 0px rgba(146, 184, 255, 0.2)"}} key={i.id}>
									<h5>{i.commented_by} says</h5>
									<p>{i.date}</p>
									<p>{i.content}</p>
								</div>
							))}
							{state.comment.length ? null : <p>No comments yet!</p>}
						
						</div>

						<div className='mt-5'>
							<h3>Add a Comment</h3>
							<p>Your email address will not be published. Required fields are marked *</p>
							<Form 
		                      name="contact_form" 
		                      className='' 
		                      onSubmit = { handleSubmit }
		                      id='form'
		                    >
		                      <Form.Row>
		                        <Form.Group as={Col} controlId="formGridEmail">
		                          <Form.Label>Name *</Form.Label>
		                          <Form.Control 
		                            type="text" 
		                            name="commented_by" 
		                            placeholder="Name" 
		                            onChange= {handleChange}
		                            value={change.commented_by} 
		                            required
		                          />
		                        </Form.Group>

		                        
		                      </Form.Row>

		                      <Form.Group  controlId="formGridPassword">
		                          <Form.Label>Phone Number</Form.Label>
		                          <Form.Control 
		                            type="tel" 
		                            name="phone_number" 
		                            placeholder="1-(555)-555-5555" 
		                            onChange= {handleChange}
		                            value={change.phone_number} 
		                            maxLength="15"
		                            
		                          />
		                        </Form.Group>
		                      <Form.Group controlId="formGridAddress2">
		                        <Form.Label>Email *</Form.Label>
		                        <Form.Control 
		                          type="email" 
		                          name="email" 
		                          placeholder="E-mail" 
		                          onChange= {handleChange}
		                          value={change.email} 
		                          required
		                        />
		                      </Form.Group>

		                      <Form.Group controlId="exampleForm.ControlTextarea1">
		                        <Form.Label>Comment *</Form.Label>
		                        <Form.Control 
		                          as="textarea"  
		                          rows="4" 
		                          name="content" 
		                          value={change.content} 
		                          placeholder="Write Your Comment..." 
		                          onChange= {handleChange}
		                          required
		                          />
		                      </Form.Group>

		                      <Button type='submit' variant='dark'>
		                      {change.loading ? 
				                    	<>
				                    	<span>Loading... </span>
				                    	<Spinner animation="border" size="sm" />
				                    	
				                    	</>
										 :
										"Save Changes" 
									}
		                  
		                     
		                          </Button>

		                      
		                    </Form>
		                </div>

					</div>
					
					<div className='col-12 col-sm-12 col-md-4 col-lg-4 p-4' style={{overflow: "hidden"}}>
						<div className='widget'>
							<h4>Posts you may like</h4>
							<hr />
							{
								data.youmaylikedata.map((i)=>{
									if (i.id!==id){
										return(
											<>
											<div key={i.id} style={{minHeight: "80px"}}>
	            								<div>
	            									<img alt="User Avatar" src={`${server}${i.cover}`}  className="mr-3"  width="80" height="80" style={{float: "left"}} />
	            								</div>
												<div>
													<Link to={`/blog/${i.category.toLowerCase()}/${i.title.replace(/ /g,"-")}/${i.id}/`} style={{color: 'inherit', fontWeight: '600'}}> 
														{i.title}
													</Link>
													<div style={{color: "#606060"}} className='mb-1'>{i.posted_on}</div>
												</div>
											</div>
											<hr />
											</>
										)
									}
									else{
										return(null)
									}

									
								})
							}
						</div>
					</div>
				</div>
				
					
				
			</div>
			
			<Footer />
		</>
	)

}