import React, { useEffect, useState } from 'react'
import NavBar from '../../components/NavBar/NavBar'
import LinkTitle from '../../components/LinkTitle/LinkTitle'
import Footer from '../../components/Footer/Footer'
import { miniblogpostAPI } from '../../api/blogpostApi'
import axios from "axios";
import BlogListTemplate from './BlogListTemplate'

export default function Blog() {
	const initialState = {
		data: [],
	}
	const [state, setState] = useState(initialState)

	useEffect((state) => {
		axios
		.get(miniblogpostAPI)
		.then((res)=>{
			
			if (res.data.status===400){
				setState({
	    	...state, data: res.data.data
	    	})
			}
			else{
				setState({
		    	...state, data: res.data
		    	})
			}
			
		})

	}, [])
	const categoryview = (
	    <BlogListTemplate 
	     	data={state.data}
	    />
    )
	return (
		<div>
		 	<NavBar />
	        <LinkTitle data='Blog' linkto='blog'/>
	        
	        {categoryview}
	        <Footer />
		</div>
	)
}