import React, { useEffect } from 'react'
import './Categorydetail.scss'
// import axios from 'axios';
// import Searchbar from '../../components/SearchBar/Searchbar'
// import Subjects from '../../components/Subjects/Subjects'
import NavBar from '../../components/NavBar/NavBar'
// import Category from '../../components/Category/Category'
import Footer from '../../components/Footer/Footer'
// import { categoryAPI } from '../../api/subjectinfoApi';
// import Dance from '../../assets/images/music.jpg';
// import Preloader from '../../components/Preloader/Preloader'

import Cards from '../../components/cards/Cards'

import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { store } from "../../Redux/store";

import { getSpecificcategory } from "../../Redux/subjectinfo/subjectinfo.actions";

import Categorytemplate from './Categorytemplate'
function Categorydetail(props) {
    useEffect(() => {
        setTimeout(() => {
            props.getSpecificcategory(props.match.params.id);
          }, 2000);
    }, [props])

    const {
      title,
      price,
      about,
      categoryimage,
      urls,
    } = props.data;
    const categoryview = (
        <Categorytemplate 

         title={title}
         price={price}
         about={about}
         teacher={props.teacher}
         categoryimage={categoryimage}
         urls={urls}
        />
    )
    return (
        <div classname='container-fluid p-0'>
            <NavBar />
            {categoryview}
           
            <div className="container section2 p-5" >
                                <br />
                                <br/>
                                <h1 className="heading2">
                                More Courses You Might Like
                                </h1>
                                <br />
                                <Cards />
                        </div>
            <Footer />    
        </div>
    )
}



Categorydetail.propTypes = {
    data: PropTypes.object,
    teacher: PropTypes.array,
};
const mapStateToProps = (state) => ({
  data: state.subject.specificcategory,
  teacher: state.subject.teacher
});

export default connect(mapStateToProps, { getSpecificcategory })(Categorydetail);
