import React, {useState,} from 'react'
import './Profile.scss'
import axios from "axios";
import {Redirect} from 'react-router-dom'
import { profilepasswordAPI } from "../../api/profileApi";
import {Button, Spinner, Form, Col,Row } from 'react-bootstrap';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { store } from "../../Redux/store";
import Fixedsettingbar from './Fixedsettingbar';
import { toast } from "react-toastify";
toast.configure();

function Passwordsettings(props) {
		const initialState = {
	      oldpassword: '',
	      newpassword1: '',
	      newpassword2: '',
	      redirect: false,
	      loading: false,

	     };
    
     const [data, setData] = useState(initialState);

     
    
     const handleInputChange = event => {
      setData({
        ...data,
        [event.target.name]: event.target.value
      });
    };

    const onSumbmitHandler = event => {
    	event.preventDefault();
    	
    	if(data.newpassword1.length<8 || data.newpassword2.length<8){
	      toast.error("Password must be 8 character long", {
	            position: "bottom-right",
	            autoClose: 5000,
	            hideProgressBar: false,
	            closeOnClick: true,
	            pauseOnHover: true,
	            draggable: true,
	            progress: undefined,
	          });
	    }
	    else if(data.newpassword1!==data.newpassword2){
	      toast.error("Password not matched", {
	            position: "bottom-right",
	            autoClose: 5000,
	            hideProgressBar: false,
	            closeOnClick: true,
	            pauseOnHover: true,
	            draggable: true,
	            progress: undefined,
	          });
	    }
	    else{
	    	setData({
		    	...data, loading: true
		    })
	    	setTimeout(() => {
	      const token = store.getState().auth.token;
          axios({
            url: `${profilepasswordAPI}`,
            method: "PATCH",
            data: {
              old_password: data.oldpassword,
              new_password: data.newpassword1,
            },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          })
            .then((res) => {
            	console.log(res.data)
            	if (res.data.status === 400){
            		setData({
				    	...data, loading: false
				    })
            		toast.error(res.data.msg, {
		                position: "bottom-right",
		                autoClose: 5000,
		                hideProgressBar: false,
		                closeOnClick: true,
		                pauseOnHover: true,
		                draggable: true,
		                progress: undefined,
		              });
            	}
            	else{
            		toast.success(res.data.msg, {
		                position: "bottom-right",
		                autoClose: 5000,
		                hideProgressBar: false,
		                closeOnClick: true,
		                pauseOnHover: true,
		                draggable: true,
		                progress: undefined,
		              });
            		setData({
				    	...data,redirect: true, loading: false
				    })

            	}
              
              
            })
            .catch((err) => {
              toast.error("Wrong Password! Enter again!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
	    }
	    , 2000);
	    }
	    

    }
if (data.redirect){
	return <Redirect to={`/myprofile/${localStorage.getItem('username')}`} />
}
	
	return (
		<div className='container-fluid '>
			

			<div className='row'>
				<div className="col-sm-12 col-md-3 col-lg-3">
					<Fixedsettingbar />
				</div>

				<div className="col-sm-12 col-md-9 col-lg-9">
			

					<div className='profilePageBody' id='profilePageBody'>
						<Row style={{ marginLeft: 0, marginRight: 0 }}>
				          <Col xl={12} lg={12} md={12} sm={12}>
				            <div className='profile-pageCard'>
				              <div>
				                <h3>Password Settings</h3>
				                <hr />
				                <Form
				                  className='profile-formAdjust'
				                  onSubmit={onSumbmitHandler}
				                >	
				                  <h5 className='profile-username'>@{props.username}</h5>
				                 
				                  <Form.Row>
				                    <Col xl={12} lg={12} md={12} sm={12}>
				                      <Form.Group >
				                        <Form.Label>Old Password*</Form.Label>
				                        <Form.Control
				                          type='password'
				                          placeholder='Your Password'
				                          name='oldpassword'
				                          value={data.oldpassword}
				                          onChange={handleInputChange}
				                          required
				                          
				                        />
				                      </Form.Group>
				                    </Col>
				                  </Form.Row>
				                  <Form.Row>
				                    <Col xl={12} lg={12} md={12} sm={12}>
				                      <Form.Group >
				                        <Form.Label>New Password*</Form.Label>
				                        <Form.Control
				                          type='password'
				                          placeholder='New Password'
				                          name='newpassword1'
				                          value={data.newpassword1}
				                          onChange={handleInputChange}
				                          required
				                          
				                        />
				                      </Form.Group>
				                    </Col>
				                  </Form.Row>
				                  <Form.Row>
				                    <Col xl={12} lg={12} md={12} sm={12}>
				                      <Form.Group >
				                        <Form.Label>Re-enter New Password*</Form.Label>
				                        <Form.Control
				                          type='password'
				                          placeholder='Re-enter New Password'
				                          name='newpassword2'
				                          value={data.newpassword2}
				                          onChange={handleInputChange}
				                          required
				                          
				                        />
				                      </Form.Group>
				                    </Col>
				                  </Form.Row>
				                  
				                  <Button type='submit' variant='dark'>
				                    {data.loading ? 
				                    	<>
				                    	<span>Loading... </span>
				                    	<Spinner animation="border" size="sm" />
				                    	
				                    	</>
										 :
										"Save Changes" 
									}
  									 
				                  </Button>
				                </Form>
				              </div>
				            </div>
				          </Col>
				        </Row>
					</div>
				</div>
			</div>
		</div>
	)
}
Passwordsettings.propTypes = {
    username: PropTypes.string,
};
const mapStateToProps = (state) => ({
  
  username: state.auth.user,
});

export default connect(mapStateToProps)(Passwordsettings);
