import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../api/server'
import { postcategorycount } from '../../api/blogpostApi'
import './Blog.scss'
export default function BlogListTemplate(props) {
	const {
      data
    } = props;
    const initialState = {
    	ctg: [],
    }
    const [state, setState] = useState(initialState)
    useEffect((state) => {
		axios
		.get(`${postcategorycount}`)
		.then((res)=>{
			setState({
	    	...state, ctg: res.data,
	    	})
		})
		

	}, [])

    let category = state.ctg.map((i, index)=>{
    	return(
    		<li className="" key={index}>
				<Link to="">{i.category.toUpperCase()}</Link>
				<span className="post-count">({i.count})</span>
			</li>
    )})

    let blogdetail = data.map((i, index)=>{   
    	return(
    		<div className="single-courses-box single-blog-post mb-4 " key={i.id}>
                <div className="courses-image">
                  <a href={`/blog/${i.category.toLowerCase()}/${i.title.replace(/ /g,"-")}/${i.id}/`} className="d-block image">
                   <img src={`${server}${i.cover}`} className="card-img" alt={i.title} style={{height: "400px"}}/> 
                  </a>
                 
                </div>
                <div className="courses-content">
                  <div className="course-author d-flex align-items-center">
                                                                        
                     <span><a href='/blog'>{i.category.toUpperCase()}</a></span>
                  </div>
                   <h5 className="card-title"><Link  to={`/blog/${i.category.toLowerCase()}/${i.title.replace(/ /g,"-")}/${i.id}/`} style={{color: 'inherit'}}>{i.title}</Link></h5>
                   <p className="card-text"><small className="text-muted">{i.posted_by} | </small><small className="text-muted">{i.posted_on}</small></p>
                </div>
              </div>
    )} )

    let posts = data.map((i, index)=>{
    	if (index<5){
    		return(
	    		<>
	    		<div key={i.id} style={{minHeight: "80px"}}>
					<div>
						<img alt="User Avatar" src={`${server}${i.cover}`}  className="mr-3"  width="80" height="80" style={{float: "left"}} />
					</div>
					<div>
						<div style={{color: "#606060"}} className='mb-1'>{i.posted_on}</div>
						<Link to={`/blog/${i.category.toLowerCase()}/${i.title.replace(/ /g,"-")}/${i.id}/`} style={{color: 'inherit', fontWeight: '600'}}> 
							{i.title}
						</Link>
						
					</div>
				</div>
				<hr />
				</>
	    	)
		}
		else{
			return(null);
		}
    	})
	return (
		<div className='container-fluid p-4' style={{backgroundColor: "#fff"}}>
			<div className='container ' style={{marginTop: "100px"}}>
				<div className="row">
					<div className='col-sm-12 col-md-12 col-lg-8'  >
						{blogdetail}
					</div>
					<div className='col-sm-12 col-md-12 col-lg-4'>
						<div className="widget mb-5">
							<h2 className="widget-title mb-4" style={{fontSize: "22px"}}>Latest Posts</h2>
							{ posts }

						</div>
						<div className="widget mb-5">
							<h2 className="widget-title mb-4" style={{fontSize: "22px"}}>Categories</h2>
							<ul>
								
								{ category }

							</ul>

						</div>


						<div className="widget mb-5">
							<h2 className="widget-title mb-4" style={{fontSize: "22px"}}>Tags</h2>
							<ul>
								<li className="">
									<a href="/blog">Education</a>
									<span className="post-count">(2)</span>
								</li>	
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}