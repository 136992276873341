import axios from 'axios';
import { toast } from 'react-toastify';
import {
  // GET_COURSES,
  GET_CATEGORIES,
  ERROR,
  // LOADING,
  GET_SPECIFICCATEGORIES,

} from './subjectinfo.types';

import { categoryAPI, courseAPI } from '../../api/subjectinfoApi';

toast.configure();

export const getCategories = () => (dispatch, getState) => {
  const config = {
    headers: {
      'content-type': 'application/json',
    },
  };

  axios
    .get(categoryAPI, config)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: GET_CATEGORIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ERROR,
      });
    });
};

export const getCourses = () => (dispatch, getState) => {
  const config = {
    headers: {
      'content-type': 'application/json',
    },
  };

  axios
    .get(courseAPI, config)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: GET_CATEGORIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ERROR,
      });
    });
};

export const getSpecificcategory = (category) => (dispatch, getState) => {
  axios
    .get(`${categoryAPI}${category}/`)
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: GET_SPECIFICCATEGORIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ERROR,
      });
    });
};
