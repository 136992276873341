import {
  GET_COURSES,
  GET_CATEGORIES,
  LOADING,
  ERROR,
  GET_SPECIFICCATEGORIES,
} from './subjectinfo.types';

const initialState = {
  count: 0,
  previous: null,
  next: null,
  course: [],
  specificcategory: {
    title: '',
    price: '',
    about: '',
    categoryimage: '',
    urls: [],
  },
  teacher: [],
  isLoading: false,
  categories: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COURSES:
      return {
        ...state,
        course: action.payload.data,
  		categories: [],
  		isLoading: false,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data,
        isLoading: false,
      };
    case LOADING:
    	return {
    		...state,
    		isLoading: true,
    	};
    case ERROR:
      return {
        ...state,
        course: [],
        categories: [],
      };
    case GET_SPECIFICCATEGORIES:
      return {
        ...state,
        specificcategory: action.payload.data,
        teacher: action.payload.data.teacher,
      };
    default:
      return state;
  }
}
