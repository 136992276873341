import React, { Component } from 'react';
import {CardDeck,} from 'react-bootstrap';
import './Cards.scss'
// import { Link } from 'react-router-dom'
import Dance from '../../assets/images/dance.jpg';
import Drawing from '../../assets/images/drawing.jpg'
import Music from '../../assets/images/music.jpg'
// import OwlCarousel from 'react-owl-carousel';
export const options = {
  responsiveClass: true,
  autoplay: true,
  loop: true,
  
  smartSpeed: 3000,
  autoWidth: true,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 1,
      },
      700: {
          items: 1,
      },
      1000: {
          items: 1,
      }
  },
};
const detail = [
    {
        img : Dance,
        title : "Dance",
        detail : "This master level course is for you if you are looking to learn the DL & ANN topics in and out within a short time!" ,
        price: 50,
        teacherimage: Dance,
        teacher: "Dev Ed"
    },
    {
        img : Drawing,
        title : "Drawing",
        detail : "This master level course is for you if you are looking to learn the DL & ANN topics in and out within a short time!" ,
        price: 50,
        teacherimage: Drawing,
        teacher: 'Rahul'
    },
    {
        img : Music,
        title : "Music ",
        detail : "This master level course is for you if you are looking to learn the DL & ANN topics in and out within a short time!" ,
        price: 50,
        teacherimage: Music,
        teacher: "prashant"
    },
]

const cards = detail.map(i => (
    
        
            <div className="col-lg-4 col-md-4 col-12 col-sm-12 mb-4">
              <div className="single-courses-box ">
                <div className="courses-image">
                  <a href="/" className="d-block image">
                    <img src={i.img} alt={i.title} />
                  </a>
                  <div className="course-price">
                    <span className="price">₹{i.price}</span>
                  </div> 
                </div>
                <div className="courses-content">
                  <div className="course-author d-flex align-items-center">
                    <img alt="User Avatar" src={i.teacherimage}  className=""  width="96" height="96" />                                                           
                     <span><a href="/">{i.teacher}</a></span>
                  </div>
                  <h3><a href="/">{i.title}</a></h3>
                  <p>{i.detail}</p>
                  
                </div>
              </div>
            </div>
        
  
    ));
    
export class Cards extends Component {
    
    render() {
        return (
            <div>
                <CardDeck>
                   <div className="row">
                   
                        { cards }
                  
                    </div>
                </CardDeck>
            </div>
        )
    }
}

export default Cards
