import React from 'react'
import Navbar from '../../components/NavBar/NavBar'
import LinkTitle from '../../components/LinkTitle/LinkTitle'
import Footer from '../../components/Footer/Footer'
import Passwordsettings from '../../components/Profile/Passwordsettings'
export default function Profilepasswordpage() {
	return (
		<div>
			<Navbar />
            <LinkTitle data='Profile' linkto='profile'/> 
            <Passwordsettings />
            <Footer />
		</div>
	)
}