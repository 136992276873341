import React, {useState, } from "react";

import Preloader from '../Preloader/Preloader'
import { Redirect, Link } from 'react-router-dom'
import './login.scss'
import {Button, Form, } from 'react-bootstrap';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login, loading } from "../../Redux/auth/auth.actions";

export const Login = (props) => {
  
  
  const initialState = {
    username: "",
    password: "",
  };

  const [data, setData] = useState(initialState);
    
 

  const handleInputChange = event => {
      setData({
        ...data,
        [event.target.name]: event.target.value
      });
    };
  const handleFormSubmit = event => {
    event.preventDefault();
    props.loading();
    setTimeout(() => {
      props.login(data.username, data.password);
    }, 1000);
    
  }

if (props.isAuthenticated) {
      
      return <Redirect to='/' />;
    }
return (
  <div className="base-container mt-5 mb-5">
        {props.isLoading ? <Preloader />: null}
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4"></div>
            <div className="col-sm-12 col-md-4 col-lg-4 login ">
            <center><h3>Login</h3></center>
            <Form
              onSubmit={handleFormSubmit}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control 
                  type="text" 
                  name="username" 
                  value={data.username} 
                  onChange={ handleInputChange } 
                  required 
                  placeholder="Username" 
                />
                
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control 
                  type="password" 
                  name="password" 
                  value={data.password} 
                  onChange={ handleInputChange } 
                  required 
                  placeholder="Password" 
                />
              </Form.Group>
             
                <Button variant="outline-primary" type="submit">
                  Submit
                </Button>
              
              
              
              <br /><br /><br />
              <hr/>
              <h6>Don't have account?<strong><br /><br /><Link to='/register' className='btn btn-outline-success'>Register</Link></strong></h6>
            </Form>
          
        
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3"></div>
          </div>
        </div>
        
      </div>
  )
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    loading: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    
    isLoading: PropTypes.bool,
  };

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, { login, loading })(Login);
