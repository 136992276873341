import React from 'react'
import Dance from '../../assets/images/dance.jpg';
import './Teachers.scss'

let detail=[
    {
        team_subtitle : "This is a wider carbit longer." ,
        team_img: Dance,
        team_title: 'Mohan'
    },
    {
        team_subtitle : "This is a wider carbit longer." ,
        team_img: Dance,
        team_title: 'Prashant'
    },
    {
        team_subtitle : "This is a wider carbit longer." ,
        team_img: Dance,
        team_title: 'Himanshu'
    },
    {
       
        team_subtitle : "This is a wider carbit longer." ,
        team_img: Dance,
        team_title: 'Pooja'
    }
]
const cards =detail.map(i => (
	<div className='col-sm-12 col-md-3 col-lg-3'>
		<div className='team p-4'>
			<div className='team_img justify-content-center text-center'>
				<img src={i.team_img} alt="" /> 
			</div>
			<div className='team_body'>
				<div className='team_title'>
					<a href='/'>{i.team_title}</a>
				</div>
				<div className='team_subtitle'>
					{i.team_subtitle} 
				</div>
				<div className='team_social'>
					<ul>
						<li>
							<a href="/">
								<i className="fa fa-instagram"></i>
							</a>
						</li>
						<li>
							<a href="/">
								<i className="fa fa-facebook"></i>
							</a>
						</li>
						<li>
							<a href="/">
								<i className="fa fa-twitter"></i>
							</a>
						</li>
					</ul>
					
				</div>
			</div>
		</div>
	</div>

));

export default function Teachers(props) {
	return (
		<>
		<div style={{color: "#fe4a55"}} className='text-center '>COURSE Instructors</div>
		<h2 className='heading2'>
			Meet Our World-class Instructors
		</h2>
		
		<div className='row'>
			
			{cards}
		</div>
	</>
	)
}