import React, {useState } from "react";

import Preloader from '../Preloader/Preloader'
import {Button, Form, Col, } from 'react-bootstrap';

import { Redirect, Link } from 'react-router-dom'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register, loading } from "../../Redux/auth/auth.actions";
import { toast } from "react-toastify";
toast.configure();
export  const Register = (props) => {

  const initialState = {
    username: '', 
    email: '', 
    password: '',
    first_name: '',
    last_name: '',
    password2: ''
  };
  const [register, setRegister] = useState(initialState);
  const handleInputChange = event => {
      setRegister({
        ...register,
        [event.target.name]: event.target.value
      });
    };
  const handleFormSubmit = event => {
    event.preventDefault();
    if(register.username.length<8){
      toast.error("Username must be 8 character long", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

    }
    else if(register.password.length<8){
      toast.error("Password must be 8 character long", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
    }
    else if(register.password!==register.password2){
      toast.error("Password Matching Wrong", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
    }
    else{
      props.loading();
      setTimeout(() => {
      props.register(
        register.username,
        register.email,
        register.password,
        register.first_name,
        register.last_name,
        
      );
    }, 1000);
    }
    }
    

if (props.isAuthenticated) {
      return <Redirect to='/' />;
    }
  return (
      <div className="base-container mt-5 mb-5" >
      {props.isLoading ? <Preloader />: null}

        <div className="container ">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3"></div>
            <div className="col-sm-12 col-md-6 col-lg-6 login ">
              <center><h3 className='p-4'>Register</h3></center>

              <div className="content">
                  <div className="">
                    <Form 
                      name="contact_form" 
                      className='' 
                      onSubmit = { handleFormSubmit }
                    >
                      <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="first_name" 
                            placeholder="First Name" 
                            onChange= {handleInputChange} 
                            required
                          />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="last_name" 
                            placeholder="Last Name" 
                            onChange= {handleInputChange} 
                            required 
                          />
                        </Form.Group>
                      </Form.Row>

                      <Form.Group controlId="formGridAddress1">
                        <Form.Label>Username</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="username" 
                          placeholder="username" 
                          onChange= {handleInputChange} 
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="formGridAddress2">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                          type="email" 
                          name="email" 
                          placeholder="E-mail" 
                          onChange= {handleInputChange} 
                          required
                        />
                      </Form.Group>

                      <Form.Row>
                        <Form.Group as={Col} controlId="formGridCity">
                          <Form.Label>Password</Form.Label>
                          <Form.Control 
                            type="password" 
                            name="password" 
                            placeholder="Password" 
                            onChange= {handleInputChange} 
                            required
                          />
                        </Form.Group>

                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formGridCity">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control 
                            type="password" 
                            name="password2" 
                            placeholder="Confirm Password" 
                            onChange= {handleInputChange} 
                            required
                          />
                        </Form.Group>

                      </Form.Row>
                      <Button variant="outline-primary" type="submit">
                        Submit
                      </Button>

                      <br /><br /><br />
                      <hr/>
                      <h6>
                        Already have account?
                        <strong>
                          <br /><br />
                          <Link to='/login' className='btn btn-outline-success'>
                            Login
                          </Link>
                        </strong>
                      </h6>
                    </Form>
                    

                      
                
                        
                        
                  </div>
                
              </div>
            </div>
            
          </div>
        </div>
        
      </div>
    );
  }

Register.propTypes = {
    register: PropTypes.func.isRequired,
    loading: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
  };
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.error,
  isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, { register, loading })(Register);
