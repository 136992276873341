export const USER_LOADING = 'USER_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const NON_FIELD_ERRORS = 'NON_FIELD_ERRORS';
export const EMAIL_CONFIRMED = 'EMAIL_CONFIRMED';
export const REMOVE_ERROR = 'REMOVE_ERROR';
